/**
 * @description: Show notification with information about article not exist on click on element with class js-article-not-exist-info.
 * Provide title and message for notification in data attributes (articleNotExistInfoTitle and articleNotExistInfoText).
 */
import {onFind} from "@elements/init-modules-in-scope";
import { showNotification, clearAll } from '@elements/alert-notification';

export function init() {
    onFind('.js-article-not-exist-info', ((trigger) => {
        trigger.addEventListener('click', onTriggerClick)
    }));

    function onTriggerClick(e) {
        const title = e.target.dataset.articleNotExistInfoTitle;
        const message = e.target.dataset.articleNotExistInfoText;

        clearAll();
        showNotification({
            type: 'danger',
            title: title,
            content: message,
            closable: true
        });
    }
}
