"use strict";
import {addClass, findIn, findAll} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import axios from "axios";
import {showNotification} from '@elements/alert-notification';

export function init() {
    //   item[] = ['id': 12333, 'productId' 1222222]

    onFind('.js-availability', function (element) {
        let url = element.getAttribute('data-availability-url');
        let articleItems = findAll('[data-item-id]');
        let data = [];

        articleItems.forEach(item => {
            let articleItemId = item.getAttribute('data-item-id');
            let articleProductId = item.getAttribute('data-product-id');
            let amountInput = findIn('.js-availability__amount', item);
            let unitInput = findIn('.js-availability__unit', item);

            if (articleItemId !== null && articleProductId !== null && amountInput !== null && unitInput !== null) {
                let items = {'id': articleItemId, 'productId': articleProductId, 'amount': amountInput.value, 'unit': unitInput.value}
                data.push(items);
            }
        });

        if(articleItems !== null && articleItems.length > 0) {
            loadAvailability(url, data);
        }
    });
}

export function loadAvailability(url, data) {
    let pendingRequest;

    //console.log({item:data})

    pendingRequest = axios({method: 'POST', url: url, params: {item:data} });

    showNotification({closable: true});
    showNotification(pendingRequest.then((x) => x.data));

    pendingRequest.then((response) => {
        if(response.data['success']) {
            let items = response.data['items']
            items.forEach((el) => {
                /* find data in response */
                let id = el.articleId;
                let stateIcon = el.stateIcon;
                let stateText = el.stateText;

                /* find dom elements */
                let articleItem = findAll('[data-item-id="'+id+'"]');

                articleItem.forEach((el) => {
                    let itemStateIcon = findIn(".js-availability__item-status", el);
                    let itemStateText = findIn(".js-availability__item-text", el);

                    /* set new data */
                    if (itemStateIcon) {
                        addClass(stateIcon, itemStateIcon);
                    }
                    if (itemStateText) {
                        itemStateText.innerHTML = stateText;
                    }
                });
            });
        }
    }).catch((error) => {
        console.warn(error);
    });

    return pendingRequest;
}