import {requireConfigValues} from '@elements/config-utils';
import {on, closest, findAllIn, findAll, findIn, trigger, removeAttribute, setAttribute} from "@elements/dom-utils";
import {showNotification} from "@elements/alert-notification";
import axios from "axios";
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import { translate } from '@elements/translations';

const configName = '_multiselectConfig';
let stickyBar = document.querySelector('.js-pds-stickybar');
let stickyBarButton;
let detailContent;
// let stickyBarCart;
let stickyBarPrice;
let stickyBarDetailPrice;
if (stickyBar) {
    stickyBarButton = findIn('.js-pds-stickybar__button', stickyBar);
    detailContent = findIn('.js-pds-stickybar__details', stickyBar);
    // stickyBarCart = findIn('.js-pds-stickydetails__cart', stickyBar);
    stickyBarPrice = findIn('.js-pds-stickybar__price', stickyBar);
    stickyBarDetailPrice = findIn('.js-pds-stickybar__detail-price', stickyBar);
}
let body = document.querySelector('body');
let clearAllUrl = requireConfigValues(['clearAllUrl'], configName);
let data = [];
let isInList = false;
export function init () {
    let updateUrl = requireConfigValues(['updateUrl'], configName);

    if(stickyBar) {
        sessionStorage.removeItem('multiselectItems');
        //loadActiveItems(updateUrl, 'load');

        onFind('.js-multiselect__add', function (button) {
            on('click', function (event) {
                event.preventDefault();
                let container = closest('[data-item-id]', this);
                let articleItemId = container.getAttribute('data-item-id');
                let articleProductId = container.getAttribute('data-product-id');
                let loading = findIn('.js-cart__loading ', container);
                let amountInput = findIn('.js-availability__amount ', container);
                let unitInput = findIn('.js-availability__unit', container);
                let cutRollNote = findIn('.js-cut-roll__parent-input', container);

                /* todo: may refactor with function from .js-multiselect-watch-input */
                if (articleItemId !== null && articleProductId !== null && amountInput !== null && unitInput !== null) {
                    let items = {'id': articleItemId, 'productId': articleProductId, 'amount': amountInput.value, 'unit': unitInput.value, 'cutRollNote': cutRollNote ? cutRollNote.value : ''};

                    /* get data from storage */
                    data = JSON.parse(sessionStorage.getItem('multiselectItems')) || [];

                    /* add new item to data */
                    data.push(items);

                    sessionStorage.setItem('multiselectItems', JSON.stringify(data));
                }

                if (this.classList.contains('is-added')) {
                    removeAttribute('hidden', loading);
                    /* remove item from data and safe in storage */
                    data = data.filter(function(el) { return el.id != articleItemId; });
                    sessionStorage.setItem('multiselectItems', JSON.stringify(data));

                    updateMultiselect(updateUrl, 'remove', data, articleItemId, this, loading);

                } else {
                    removeAttribute('hidden', loading);

                    updateMultiselect(updateUrl, 'add', data, articleItemId, this, loading);
                }

            }, button);
        });


        onFind('.js-pds-stickydetails__toggle', function (button) {
            on('click', function (event) {
                event.preventDefault();
                toggleDetails();
            }, button);
        });


        onFind('.js-pds-stickydetails__cart', function (button) {
            on('click', function (event) {
                event.preventDefault();
                let cartBtn = findAllIn('.js-cart__link', detailContent);

                cartBtn.forEach(function (cartBtn) {
                    trigger('click', cartBtn);
                });
            }, button);
        });
    }
}



export function updateMultiselect(url, action, data, articleItemId, button, loading) {
    let pendingRequest = axios({method: 'POST', url: url + "&" + action + "=1", data: {item:data} });

    showNotification({closable: true});
    showNotification(pendingRequest.then((x) => x.data));

    pendingRequest.then((response) => {
        let result = response.data;

        if (result.success) {
            setDetailContent(result, button);
            if (result.count > 0) {
                // stickyBarCart.classList.add('disabled');
                stickyBarDetailPrice = findIn('.js-pds-stickybar__detail-price', stickyBar);
                stickyBarPrice.innerHTML = '';
                stickyBarDetailPrice.innerHTML = '';
            }
            // BE sends us an array with the active articles because articles with different units are being merged
            // e.g. 10 sheets + 1 package could be 510 sheets
            if (result.activeArticles) {
                sessionStorage.setItem('multiselectItems', JSON.stringify(result.activeArticles));
                updateCutRollInfo()
            }
            setButtonStyles(result, action, button);
            setAttribute('hidden', true, loading);
        }
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    });
}

export function clearMultiselect() {
    let pendingRequest = axios({method: 'GET', url: clearAllUrl});

    showNotification({closable: true});
    showNotification(pendingRequest.then((x) => x.data));

    pendingRequest.then((response) => {
        let result = response.data;

        if (result.success) {
            stickyBar.classList.remove('pds-stickybar--open');
            body.classList.remove('no-scroll');
        }
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    });
}

export function toggleDetails() {
    if (stickyBar.classList.contains('pds-stickydetails--open')) {
        stickyBar.classList.remove('pds-stickydetails--open');
        body.classList.remove('no-scroll');
    } else {
        stickyBar.classList.add('pds-stickydetails--open');
        body.classList.add('no-scroll');
    }
}

export function closeDetails() {
    if (stickyBar.classList.contains('pds-stickydetails--open')) {
        stickyBar.classList.remove('pds-stickydetails--open');
        body.classList.remove('no-scroll');
    }
}

export function openDetails() {
    stickyBar.classList.add('pds-stickydetails--open');
    body.classList.add('no-scroll');
}

export function setDetailContent(result) {
    stickyBarButton.innerHTML = result.button;

    //set detail content
    if (result.html) {
        detailContent.innerHTML = result.html;

        if (result.count === 0) {
            stickyBar.classList.remove('pds-stickybar--open');
            closeDetails();
        } else {
            stickyBar.classList.add('pds-stickybar--open');
        }
        initInScope(detailContent);
    }
}
export function setButtonStyles(result, action, btn) {
    let activeArticles = result.activeArticles;
    if(btn && action === 'remove') {
        let container = closest('[data-item-id]', btn);
        let itemId = container.getAttribute('data-item-id');
        let parentItem = findAll('[data-item-id="'+itemId+'"]');

        parentItem.forEach(function (item) {
            let button = findIn('.js-multiselect__add', item);
            if(button) {
                button.classList.remove('is-added');
                button.innerHTML= "<span class='icon icon-plus'></span>" + translate('multiselect.add');
            }
        });
    } else {
        for (const article of activeArticles) {
            let parentItems = findAll('[data-item-id="'+ article.articleId +'"]');

            parentItems.forEach(function (item) {
                let button = findIn('.js-multiselect__add', item);
                if(button) {
                    button.classList.add('is-added');
                    button.innerHTML = `<span class='icon icon-minus'></span>${translate('multiselect.remove')}`;
                }
            });
        }
    }
}



export function loadActiveItems(url, action) {
    let pendingRequest = axios({method: 'GET', url: url });

    pendingRequest.then((response) => {
        let result = response.data;

        if (result.success) {
            if (result.count > 0) {
                setDetailContent(result);
                // stickyBarCart.classList.add('disabled');
                setButtonStyles(result, action, false);
            }
        }
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    });
}

export function updateCutRollInfo() {
    const stickyBarWrapper = document.querySelector('.js-pds-stickybar__details');
    const pdsArticles = stickyBarWrapper.querySelectorAll('.pds-article');
    const multiselectItems = JSON.parse(sessionStorage.getItem('multiselectItems')) || [];

    if (multiselectItems.length === 0) return;

    pdsArticles.forEach(article => {
        const articleId = article.querySelector('[data-article-id]').getAttribute('data-article-id');
        const matchingItem = multiselectItems.find(item => item.articleId === articleId);
        const cutRollToggle = article.querySelector('.js-cut-roll__toggle')

        if (!cutRollToggle) return;

        article.classList.add('info-added')

        if (matchingItem) {
            const cutRollNote = matchingItem.cutRollNote;
            const inputElement = article.querySelector('.js-cut-roll__parent-input');

            if (inputElement) {
                cutRollToggle.classList.add('cut-roll__btn--active');
                inputElement.setAttribute('value', cutRollNote);
            }
        }
    });
}