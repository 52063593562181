import {getConfigValue, requireConfigValues} from '@elements/config-utils';
import {showNotification, clearAll} from '@elements/alert-notification';
import formDataEntries from 'form-data-entries';
import {closeDetails, clearMultiselect} from './multiselect-modal';
import {
    findIn,
    findAll,
    on,
    find,
    removeAttribute,
    setAttribute,
    addClass,
    removeClass,
    findAllIn,
    trigger,
    closest
} from '@elements/dom-utils';
import {onFind, initInScope, cleanUpInScope} from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "@elements/data-set-utils";

const configName = '_cartConfig';
import axios from "axios";
import Alert from 'bootstrap/js/dist/alert';
import {resultTracking} from "@elements/tracking";

onFind('.alert', function (element) {
    new Alert(element);
});
let count,
    namePlaceholder,
    totalPricePlaceholder,
    vatPlaceholder,
    listContent = document.querySelectorAll('.js-cart__result'),
    stickyBar = document.querySelector('.js-pds-stickybar'),
    body = document.querySelector('body');

const defaultSelectors = {
    base: '.js-cart',
    form: '.js-cart__form',
    addButton: '.js-cart__add',
    removeButton: '.js-cart__remove',
    loading: '.js-cart__loading',
    result: '.js-cart__result',
    link: '.js-cart__link',
    count: '.js-cart__count'
};

const defaultOptions = {
    formTrigger: 'submit',
    emptyCart: false,
    onAdd: () => {
    },
    onAddSucceed: () => {
    },
    onAddFailed: () => {
    }
};


export function init(options = defaultOptions, selectors = defaultSelectors) {
    count = findAll(selectors.count);
    onFind(selectors.form, function (form) {
        let container = closest('.js-cart', form) ? closest('.js-cart', form) : form,
            isModal = form.getAttribute('data-cart-modal');

        if (isModal) {
            container = form;
        }

        options = {
            ...defaultOptions,
            ...options,
            ...getPrefixedDataSet('cart', form)
        };

        let formTrigger = form.getAttribute('data-cart-form-trigger') ? form.getAttribute('data-cart-form-trigger') : 'submit';

        requireConfigValues(['updateUrl'], configName);

        const handleForm = (evt) => {
            evt.preventDefault();

            let formDataEntries = getFormDataEntries([form]),
                params = new URLSearchParams(formDataEntries),
                url = form.getAttribute('data-action') || getConfigValue('updateUrl', configName);

            updateCart(url, params, container, options);

            call(options.onAdd);
        }

        if (form && form.length) {
            form.addEventListener(formTrigger, handleForm);
        }

        return {
            cleanUp: () => {
                form.removeEventListener(formTrigger, handleForm);
            }
        };
    });

    onFind(selectors.link, function (link) {
        options = {
            ...defaultOptions,
            ...options,
            ...getPrefixedDataSet('cart', link)
        };

        const handleClick = (evt) => {
            evt.preventDefault();

            if (link.getAttribute('data-cart')) {
                let params = JSON.parse(link.getAttribute('data-cart'));
                updateCart(getConfigValue('updateUrl', configName), params, closest('.js-cart', link), options, !!link.getAttribute('data-cart-add-params-to-url'));
            } else {
                updateCart(link.getAttribute('href'), {}, closest('.js-cart', link), options);
            }

            call(options.onAdd);
        }

        if (link) {
            link.addEventListener('click', handleClick);
        }

        return {
            cleanUp: () => {
                link.removeEventListener('click', handleClick);
            }
        };
    });

    onFind(selectors.base, function (baseElement) {
        createCart(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });

    onFind(selectors.removeButton, function (removeButton) {
        on('click', function () {
            let params = new URLSearchParams({
                removeId: removeButton.getAttribute('data-product-id'),
                cartItemId: removeButton.getAttribute('data-cartitem-id')
            });

            updateCart(getConfigValue('updateUrl', configName), params, find(selectors.base), options);
        }, removeButton);
    });

    onFind('.js-cart__dropdown-toggle', function (button) {
        let dropdown = closest('.js-cart__dropdown', button);
        on('show.bs.dropdown', () => {
            getCartInfo(findIn('.js-cart__dropdown-loading', dropdown), findIn('.js-cart__dropdown-result', dropdown));
        }, button);
    });

    onFind('.js-cart-add-all__btn', function (addButton) {
        on('click', function () {
            let container = closest('.js-cart-add-all', addButton);
            let articlesHaveAddButton = findAllIn('button[data-cart]', container).length > 0;

            let addParams = [];

            if (!articlesHaveAddButton) {
                findAllIn('[data-cart]', container).map((item) => {
                    addParams.push(JSON.parse(item.getAttribute('data-cart')));
                });
            } else {
                findAllIn('button[data-cart]', container).map((item) => {
                    addParams.push(JSON.parse(item.getAttribute('data-cart')));
                });
            }

            updateCart(getConfigValue('updateUrl', configName), addParams, closest('.js-cart-add-all', addButton), options);

            if (addButton.getAttribute("data-stickybar")) {
                closeDetails()
            }

        }, addButton);
    });


    getCartInfo();
}

export function createCart(baseElement, options = defaultOptions, selectors = defaultSelectors) {
    options = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('cart', baseElement)
    };

    requireConfigValues(['infoUrl'], configName);


    findAllIn('.js-cart__item', baseElement).map((item) => {
        let productId = item.getAttribute('data-product-id'),
            cartItemId = item.getAttribute('data-cartitem-id');

        on('change', function (evt) {
            let params = new FormData();

            params.append('cartItemId', cartItemId)
            params.append('productId', productId)
            findAllIn('input, select', item).map((input) => params.append(input.name, input.value))

            params = new URLSearchParams(params);

            updateCart(getConfigValue('updateUrl', configName), params, baseElement, options);

        }, item);


        let removeButton = findIn(selectors.removeButton, item);
        if (removeButton) {
            on('click', function () {
                let params = new URLSearchParams({removeId: productId, cartItemId: cartItemId});

                updateCart(getConfigValue('updateUrl', configName), params, baseElement, options);
            }, removeButton);
        }
    });
}

export function updateCart(url, params, baseElement, options = defaultOptions, addParamsToUrl) {
    let loadingElement = findIn('.js-cart__loading', baseElement);

    if (loadingElement) {
        removeAttribute('hidden', loadingElement);
    }

    addClass('is-loading', baseElement);

    let pendingRequest;

    if (addParamsToUrl) {
        pendingRequest = axios({method: 'POST', url: url, params: params});
    } else {
        pendingRequest = axios({method: 'POST', url: url, data: params});
    }

    pendingRequest.then((response) => {
        let result = response.data;

        if (result.success) {

            //set list content
            // if (result.content && listContent) {
            if (result.content) {
                //todo
                // listContent[0].innerHTML = result.content;

                if (result.count === 0) {
                    findAll('.js-cart__submit').map((button) => addClass('disabled', button));
                } else {
                    findAll('.js-cart__submit').map((button) => removeClass('disabled', button));
                }

                if (result.isMultiselect) {
                    closeDetails();
                    clearMultiselect();
                }

                // cleanUpInScope(listContent[0]);
                // initInScope(listContent[0]);
            }

            //set count
            if (typeof result.count !== "undefined") {
                setCount(count, result.count);
            }

            // set loading state
            addClass('is-finished', baseElement);

            let t = setTimeout(() => {
                removeClass('is-finished', baseElement);

                clearTimeout(t);
            }, 3000);

            call(options.onAddSucceed);
            resultTracking(result);
        } else {
            call(options.onAddFailed);
        }
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    }).then(() => {
        // always executed
        if (loadingElement) {
            setAttribute('hidden', true, loadingElement);
        }

        removeClass('is-loading', baseElement);
    });

    clearAll();
    showNotification({closable: true});
    showNotification(pendingRequest.then((x) => x.data));
}

export function getCartInfo(loadingIndicator, resultContainer) {
    requireConfigValues(['infoUrl'], configName);

    if (loadingIndicator) {
        removeAttribute('hidden', loadingIndicator);
    }

    let request = axios({method: 'GET', url: getConfigValue('infoUrl', configName)});

    request.then((response) => {
        let result = response.data;
        if (result.success) {
            if (typeof result.count !== "undefined") {
                setCount(count, result.count);
            }

            //set list content
            if (result.content) {
                resultContainer.innerHTML = result.content;

                if (result.count === 0) {
                    findAll('.js-cart__submit').map((button) => addClass('disabled', button));
                } else {
                    findAll('.js-cart__submit').map((button) => removeClass('disabled', button));
                }

                // cleanUpInScope();
                // cleanUpInScope(baseElement.parentElement);
                // initInScope(baseElement.parentElement);
            }

            if (loadingIndicator) {
                setAttribute('hidden', true, loadingIndicator);
            }
        }
    }).catch(e => {
        console.warn(e);
    });
}

function getFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        for (let pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }
    });

    return formDataArray;
}

function setCount(elements, count) {
    elements.map((item) => {
        if (item.getAttribute('data-count-visible')) {
            removeAttribute('hidden', item);
            addClass('is-active', item);
            item.textContent = count;
        } else {
            if (count) {
                removeAttribute('hidden', item);
                addClass('is-active', item);
                item.textContent = count;
            } else {
                elements.map((item) => {
                    removeClass('is-active', item);
                    setAttribute('hidden', true, item);
                });
            }
        }
    });
}

// Call the given function if it really is one
function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}