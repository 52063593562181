import {findAllIn, findIn, on, addClass, removeClass, find, setAttribute, removeAttribute, hasClass, trigger} from "@elements/dom-utils";
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import axios from "axios";
import {openDetails} from "./multiselect-modal";
import Modal from "bootstrap/js/dist/modal";
import { translate } from '@elements/translations';
import {showNotification} from '@elements/alert-notification';

export function init () {
    onFind('.js-multi-price', (container) => {
        let submitAll = findIn('.js-multi-price__submit', container),
            form = findIn('.js-multi-price__form', container),
            progress = findIn('.js-multi-price__progress', container),
            progressBar = findIn('.js-multi-price__progress-bar', progress),
            cartButton = findIn('.js-multi-price__cart', container),
            totalPrice = findIn('.js-multi-price__total-price', container);

        let alertModal = find('.js-multi-price-alert');
        let alertModalInstance = Modal.getOrCreateInstance(alertModal);

        let alertModalCalcNew = findIn('.js-multi-price-alert__calc-new', alertModal),
            alertModalCalcCurrent = findIn('.js-multi-price-alert__calc-current', alertModal),
            errors = [];

        on('change', () => {
            setAttribute('disabled', true, cartButton);
            totalPrice.innerHTML = '';
        }, container);

        const updateProgress = (completed, total) => {
            progressBar.style.width = Math.round(completed / total * 100) + '%';
            progressBar.innerHTML = Math.round(completed / total * 100) + '%';
        }
        if(submitAll) {
            on('click', function () {
                let detailForms = findAllIn('.js-price-calc-form__form', container);
                errors = [];

                addClass('is-pending', progress);

                let promises = [];
                detailForms.map((form) => {
                    let params = new URLSearchParams(new FormData(form));
                    let url = addSearchParamsToUrl(form.getAttribute('data-action'), params);

                    if (url instanceof URL) {
                        url = url.href;  // Convert URL object to string for axios
                    }
                    promises.push(
                        axios({
                            method: 'get',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            url: url,
                            responseType: 'json'
                        }).then(({data}) => {
                            if (data.showModal) {
                                errors.push({
                                    articleId: data.articleId,
                                    productId: data.productId,
                                    title: data.label,
                                    requested: {
                                        quantity: data.requestedQuantity,
                                        formattedQuantity: data.formattedRequestedQuantity,
                                        unit: data.requestedUnit,
                                    },
                                    confirmed: {
                                        quantity: data.confirmedQuantity,
                                        formattedQuantity: data.formattedConfirmedQuantity,
                                        unit: data.confirmedUnit,
                                    }
                                });
                            }

                            if(data.html && findIn('.js-price-calc-form__result', form) && data.showModal !== true) {
                                findIn('.js-price-calc-form__result', form).innerHTML = data.html;
                                initInScope(findIn('.js-price-calc-form__result', form));
                            }


                            if(data.detailPrice && findIn('.js-multi-price__detail-price', container)) {
                                findIn('.js-multi-price__detail-price', container).innerHTML = data.detailPrice;
                            }

                            if(!hasClass('.pds-stickydetails--open', container)) {
                                openDetails();
                            }
                        })
                    );
                });

                progressPromise(promises, updateProgress).then(() => {
                    removeClass('is-pending', progress);
                    removeAttribute('disabled', progress);

                    let t = setTimeout(() => {
                        updateProgress(0, detailForms.length);

                        clearTimeout(t);
                    }, 300);

                    if (errors.length) {
                        console.log(errors)
                        let errorContent = (`<div class="table-responsive"><table class="table table-striped">
<thead><tr><th>${translate('multi-select.price-article')}</th><th>${translate('multi-select.price-requested')}</th><th>${translate('multi-select.price-confirmed')}</th></tr></thead>
<tbody>
` + errors.map((error) => {return `<tr>
<td>${error.title}</td>
<td>${error.requested.formattedQuantity} ${error.requested.unit}</td>
<td>${error.confirmed.formattedQuantity} ${error.confirmed.unit}</td>
</tr>`}) + `</tbody></table>`);

                        findIn('.js-multi-price-alert__content', alertModal).innerHTML = errorContent;
                        alertModalInstance.show();
                    } else {
                        removeAttribute('disabled', progress);
                    }
                });

                trigger('multi-price:submit', form);
            }, submitAll)
        }

        on('click', () => {
            alertModalInstance.hide();

            addClass('is-pending', progress);

            let promises = [];
            errors.map((error) => {
                console.log(error)
                let errorForm = findIn(`[data-article-id="${error.articleId}"]`, container);

                findIn('select[name="unit"]', errorForm).value = error.confirmed.unit;
                findIn('input[name="amount"]', errorForm).value = error.confirmed.quantity;

                promises.push(handlePriceRequest(errorForm, alertModal.getAttribute('data-url'), error.articleId, error.productId));
            });
            progressPromise(promises, updateProgress).then(() => {
                removeClass('is-pending', progress);
                removeAttribute('disabled', progress);

                // submit form again
                trigger('multi-price:submit', form);

                let t = setTimeout(() => {
                    updateProgress(0, promises.length);

                    clearTimeout(t);
                }, 300);
            });
        }, alertModalCalcNew);

        on('click', () => {
            alertModalInstance.hide();

            addClass('is-pending', progress);

            let promises = [];
            errors.map((error) => {
                console.log(error)
                let errorForm = findIn(`[data-article-id="${error.articleId}"]`, container);

                promises.push(handlePriceRequest(errorForm, alertModal.getAttribute('data-url'), error.articleId, error.productId));
            });

            progressPromise(promises, updateProgress).then(() => {
                removeClass('is-pending', progress);
                removeAttribute('disabled', progress);

                // submit form again
                trigger('multi-price:submit', form);

                let t = setTimeout(() => {
                    updateProgress(0, promises.length);

                    clearTimeout(t);
                }, 300);
            });
        }, alertModalCalcCurrent);

        // get total price of all items
        on('multi-price:submit', (evt) => {
            let items = [];

            findAllIn('.js-price-calc-form__form', container).map((item) => {
                if (!hasClass('js-multi-price__form', item)) {
                    items.push({
                        id: item.getAttribute('data-article-id'),
                        productId: item.getAttribute('data-product-id'),
                        amount: findIn('.js-price-calc-form__form-amount', item).value,
                        unit: findIn('.js-price-calc-form__form-unit', item).value
                    })
                }
            });

            let params = new URLSearchParams();
            params.append('items', JSON.stringify(items));

            axios({
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                },
                url: form.getAttribute('data-action'),
                params: params,
                responseType: 'json'
            }).then(({data}) => {
                if(data.stickyPrice && totalPrice) {
                    totalPrice.innerHTML = data.stickyPrice;
                }
            });
        }, form);
    });
}
function progressPromise(promises, tickCallback) {
    var len = promises.length;
    var progress = 0;

    function tick(promise) {
        promise.then(function () {
            progress++;
            tickCallback(progress, len);
        });
        return promise;
    }

    return Promise.all(promises.map(tick));
}

function addSearchParamsToUrl(url, searchParams) {
    url = new URL(url, location.origin);

    let searchParamsArray = Array.from(searchParams);
    searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
    searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));

    return url;
}

const handlePriceRequest = (form, apiUrl, articleId, productId) => {
    let params = new URLSearchParams();
    params.append('articleId', articleId);
    params.append('productId', productId);

    let url = addSearchParamsToUrl(apiUrl, params);

    let pendingRequest = axios({
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        url: url,
        data: new FormData(form),
        responseType: 'json'
    });

    showNotification({closable: true});
    showNotification(pendingRequest.then((x) => x.data));

    pendingRequest.then(({data}) => {
        if(data.html && findIn('.js-price-calc-form__result', form) && data.showModal !== true) {
            findIn('.js-price-calc-form__result', form).innerHTML = data.html;
            initInScope(findIn('.js-price-calc-form__result', form));
        }
    })

    return pendingRequest
}