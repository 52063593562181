// import {isParsleyForm, isValid} from "@elements/parsley-bootstrap-validation";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import 'url-polyfill';
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from '@elements/fetch'; // IE10 Polyfill
import {debounce} from "debounce";
import asyncAppend from '../libs/@elements/custom-async-append';
import formDataEntries from 'form-data-entries';
import {findAllIn, findIn, on, is, findAll, off, trigger, triggerWith, setAttribute, removeAttribute, closest, hasClass} from "@elements/dom-utils";
import Modal from "bootstrap/js/dist/modal";
//import closeDetails from 'multisection-modal';
import {initInScope,onFind} from "@elements/init-modules-in-scope";
import {openDetails} from "../scripts/multiselect-modal";
import { showNotification, clearAll } from '@elements/alert-notification';
import { translate } from '@elements/translations';

const defaultSelectors = {
    base: '.js-price-calc-form',
    result: '.js-price-calc-form__result',
    loading: '.js-price-calc-form__loading',
    notifications: '.js-price-calc-form__notifications',
    form: '.js-price-calc-form__form',
    additionalForm: '.js-price-calc-form__additional-form',
    errorArea: '.js-price-calc-form__error-area',
    retry: '.js-price-calc-form__retry',
    link: '.js-price-calc-form__link',
    reset: '.js-price-calc-form__reset'
};

const defaultOptions = {
    submitOnChange: false,
    addUrlParams: false,
    submitOnReset: false,
    fetchHeaders: {},
    allowScriptTags: false,
    validator: () => true,
    onSubmit: () => {}
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createAjaxForm(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createAjaxForm(baseElement, options = defaultOptions, selectors = defaultSelectors) {
    let pendingRequest = null;
    let form = findIn(selectors.form, baseElement);
    let results = findAllIn(selectors.result, baseElement);
    let loadingElements = findAllIn(selectors.loading, baseElement);
    let notifications = findAllIn(selectors.notifications, baseElement);
    let errorAreas = findAllIn(selectors.errorArea, baseElement);
    let body = document.querySelector('body');

    options = {
        ...defaultOptions,
        ...options,
        ...transformDataSetOptions(getPrefixedDataSet('ajax-form', baseElement))
    };

    // form
    onFind(selectors.form, function (form) {
        const submitHandler = evt => {
            evt.preventDefault();
            evt.stopImmediatePropagation(); // otherwise .on('submit.ajax-form') would be called twice
            submitForm(form, evt);
        };

        const changeHandler = debounce(() => {
            submitForm(form);
        }, 200);

        on('submit', submitHandler, form);

        if (options.submitOnChange) {
            on('change', changeHandler, form);
        }

        return () => {
            off('submit', submitHandler, form);
            off('change', changeHandler, form);
        }
    }, baseElement);

    // additional forms
    onFind(selectors.additionalForm, function (additionalForm) {
        const submitHandler = evt => {
            evt.preventDefault();
            submitForm(additionalForm, evt);
        };

        const changeHandler = debounce(function () {
            submitForm(additionalForm);
        }, 200);

        on('submit', submitHandler, additionalForm);

        if (options.submitOnChange || additionalForm.dataset.ajaxFormSubmitOnChange) {
            on('change', changeHandler, additionalForm)
        }

        return () => {
            off('submit', submitHandler, additionalForm);
            off('change', changeHandler, additionalForm);
        }
    }, baseElement);

    // retry
    findAllIn(selectors.retry, baseElement)
        .map(on('click', (evt) => {
            evt.preventDefault();

            if (lastLoadParams) {
                load(...lastLoadParams);
            }
        }));

    // links
    onFind(selectors.link, (link) => {
        function linkClickHandler(evt) {
            evt.preventDefault();

            let closestForm = closest('form', link);

            let href = link.getAttribute('href') || link.dataset.href;
            let action = closestForm ? getActionByForm(closestForm) : form.dataset.action || form.getAttribute('action');
            let params = new URL(href, location.origin).searchParams;

            pendingRequest = load(action, 'GET', params, href);

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error) => {
                    if ((!error || error.name !== 'AbortError')) {
                        pendingRequest = null;
                    }
                });
        }

        on('click', linkClickHandler, link);

        return () => {
            off('click', linkClickHandler, link);
        }
    }, baseElement);

    // submitAll
    // let stickyBar = document.querySelector('.js-pds-stickybar');
    // if(stickyBar) {
    //     let submitAll = document.querySelectorAll('.js-price-calc-form__calculate-all');
    //     let stickyDetails = document.querySelectorAll('.js-pds-stickybar__details');
    //     let detailForms = findAllIn('.js-price-calc-form__form', stickyDetails[0]);
    //     if(submitAll[0]) {
    //         on('click', function () {
    //             /* submit all forms for single-price calculation */
    //             detailForms.forEach(function (form) {
    //                 submitForm(form);
    //             });
    //         }, submitAll[0]);
    //     }
    // }

    let lastLoadParams = null; // for retry
    function load(url, method, params, historyUrl) {
        method = method || "GET";
        lastLoadParams = [url, method, params, historyUrl];
        let modalShown = false;


        if (options.addUrlParams) {
            history.replaceState(history.state, document.title, historyUrl || url);
        }

        // add base url to params (path)
        params.append('baseUrl', location.pathname);

        if (method.toUpperCase() === "GET") {
            params.append('ajax', 1);
            // Add ajax param to differentiate between and ajax requests and page request.
            // Otherwise Chrome caches these results as normal pages and returns them from cache if the back button is pressed
            url = addSearchParamsToUrl(url, params);
        }

        let request = fetch(url, {
            method: method,
            headers: {
                'pragma': 'no-cache',
                'cache-control': 'no-cache',
                ...options.fetchHeaders
            },
            ...(method.toUpperCase() !== "GET" ? {
                body: new URLSearchParams(params)
            } : {})
        });

        triggerWith('fetch.ajax-form', request, baseElement);

        let targetsByResultId = {};
        results.forEach((element) => {
            let resultId = element.dataset.resultId || 'default';
            if (targetsByResultId[resultId]) {
                targetsByResultId[resultId] = [...targetsByResultId[resultId], element];
            } else {
                targetsByResultId[resultId] = [element];
            }
        });

        request.then(response => (response
            && response.json
            && typeof response.json === 'function'
            && response.clone
            && typeof response.clone === 'function')
            ? response.clone().json()
            : response
        )
            .then((result) => {
                if(result.showModal) {
                    modalShown = true;
                    /* modal fun*/
                    let myModal = document.getElementById('dynamicModal');
                    let myModalEl = Modal.getOrCreateInstance(myModal);

                    //set modal options backdrop static
                    myModalEl._config.backdrop = 'static';
                    myModalEl._config.keyboard = false;

                    let myModalContent = findIn('.js-dynamic-modal__content', myModal);
                    myModalContent.innerHTML = result.html;
                    myModalEl.show();
                    checkForLoaderRemoveBtn(myModalEl, loadingElements);
                    initInScope(myModalContent);

                    myModal.addEventListener('hidden.bs.modal', function (event) {
                        myModalEl._config.backdrop = true;
                        myModalEl._config.keyboard = true;
                    })
                }

                if(result.isMultiselect) {
                    let stickyDetailPrice = document.querySelector('.js-pds-stickybar__detail-price');
                    let stickyPrice = document.querySelector('.js-pds-stickybar__price');
                    let stickySubmit = document.querySelector('.js-pds-stickydetails__cart');

                    stickySubmit.classList.remove('disabled');
                    openDetails();

                    if(result.detailPrice) {
                        stickyDetailPrice.innerHTML = result.detailPrice;
                    }

                    if(result.stickyPrice) {
                        stickyPrice.innerHTML = result.stickyPrice;
                    }
                }

                asyncAppend(
                    {
                        target: modalShown ? false : targetsByResultId, //check this
                        loading: loadingElements,
                        notifications: notifications,
                        options: {
                            allowScriptTags: options.allowScriptTags
                        }
                    },
                    request
                )
                    .then((result) => {
                        let content = result.html || result.content;
                        if (content && result.success !== false) {

                            //modal for discount-differs-from-customer-price.html.twig
                            if (result.modal) {
                                modalShown = true;
                                /* modal fun*/
                                let myModal = document.getElementById('dynamicModal');
                                let myModalEl = Modal.getOrCreateInstance(myModal);

                                //set modal options backdrop static
                                myModalEl._config.backdrop = 'static';
                                myModalEl._config.keyboard = false;

                                let myModalContent = findIn('.js-dynamic-modal__content', myModal);
                                myModalContent.innerHTML = result.modal;
                                myModalEl.show();
                                initInScope(myModalContent);

                                myModal.addEventListener('hidden.bs.modal', function (event) {
                                    myModalEl._config.backdrop = true;
                                    myModalEl._config.keyboard = true;
                                })
                            }

                            if (result.showModal) {
                                loadingElements.map(removeAttribute('hidden'));
                            } else {
                                loadingElements.map(setAttribute('hidden','hidden'));
                            }

                            trigger('success.price-calc-form', baseElement);
                            errorAreas.map(setAttribute('hidden', 'hidden'));

                            let newForm = findIn(selectors.form, baseElement);
                            if (newForm) {
                                form = newForm;
                            }

                            if (result.amount !== params.get("amount")*1 && params.get("amount")*1 !== 0 && result.amount !== undefined){
                                findIn('.js-availability__amount', newForm).value = result.amount;

                                clearAll();
                                showNotification({
                                    type: 'info',
                                    title: translate('amount-notification.title'),
                                    content: `${translate('amount-notification.old-amount')} ${params.get("amount")} ${translate('amount-notification.rounded-amount')} ${result.amount}`,
                                    closable: true
                                });
                            }
                        } else {
                            trigger('failed.price-calc-form', baseElement);
                            errorAreas.map(removeAttribute('hidden'));
                            errorAreas.parentNode.classList.remove('mt-2');
                        }

                        triggerWith('fetched.price-calc-form', result, baseElement);
                    })
                    .catch(() => {
                    });

            })

        // Unpack json response body if the promise was created via fetch
        // Otherwise the HTTP-Server error is not caught.
        // The fetch promise itself resolves (even with a http error)
        request.then(response => (response
            && response.json
            && typeof response.json === 'function'
            && response.clone
            && typeof response.clone === 'function')
            ? response.clone().json()
            : response
        ).catch((error, requestState) => {
            if (!error || error.name !== 'AbortError') {
                console.error(error);
                trigger('failed.price-calc-form', baseElement);
                errorAreas.map(removeAttribute('hidden'));
            }
        });

        return request;
    }

    function submitForm(form, evt) {
        let submitBtn;
        let action;

        if(evt !== undefined && evt.submitter){
            submitBtn = evt.submitter;
            if(submitBtn.getAttribute('formaction')){
                action = submitBtn.getAttribute('formaction');
            }
        }else{
            action = getActionByForm(form);
        }

        return submit(
            action,
            form.dataset.method || form.getAttribute('method'),
            submitBtn
        )
    }

    function submit(action, method, submitBtn) {
        action = action || getActionByForm(form);
        method = method || form.dataset.method || form.getAttribute('method');

        loadingElements.map(removeAttribute('hidden'));

        // create promise to resolve/reject in right order (important for loading-indicator with multiple submissions)
        let readyToSubmit = new Promise(function (resolve, reject) {
            if (pendingRequest && pendingRequest.abort) {
                pendingRequest.abort();
                pendingRequest.catch(resolve);
                pendingRequest = null;
            } else {
                resolve();
            }
        });

        readyToSubmit.then(function () {
            if (typeof options.validator === "function" && !options.validator({
                baseElement,
                form
            })) {
                return;
            }

            trigger('submit.ajax-form', baseElement);

            action = action || getActionByForm(form);
            method = method || form.dataset.method || form.getAttribute('method');
            let formDataEntries = getFormDataEntries();

            if(submitBtn !== undefined && submitBtn.name.length > 0 && submitBtn.value.length > 0){
                formDataEntries.push([submitBtn.name, submitBtn.value]);
            }

            let params = new URLSearchParams(formDataEntries);

            options.onSubmit({
                $element: baseElement,
                options,
                formData: getFormData()
            });

            let url = new URL(location.href);
            url.searchParams.delete('page');
            url = addSearchParamsToUrl(url, params);

            pendingRequest = load(action, method, params, url);

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error) => {
                    if (!error || error.name !== 'AbortError') {
                        pendingRequest = null;
                    }
                });
        });
    }

    function getFormData() {
        return createFormData([form, ...findAllIn(selectors.additionalForm, baseElement)]);
    }

    function getFormDataEntries() {
        return createFormDataEntries([form, ...findAllIn(selectors.additionalForm, baseElement)]);
    }

    let api = {
        submit,
        getFormData,
        getFormDataEntries // todo add to doku
    };

    baseElement.ajaxForm = api;

    return api;
}

function addSearchParamsToUrl(url, searchParams) {
    url = new URL(url, location.origin);

    let searchParamsArray = Array.from(searchParams);
    searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
    searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));

    return url;
}

function createFormData(forms) {
    let formData = new FormData();
    forms.map(form => {
        for (var pair of formDataEntries(form)) {
            formData.append(...pair);
        }
    });

    return formData;
}

function createFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        // unchecked checkboxes and radios needs to be added manually to formDataArray
        let selectors = findAllIn('input[type="radio"], input[type="checkbox"]', form);
        let selectorNames = [];

        // selects needs to be added manually to formDataArray
        let selects = form.getElementsByTagName("SELECT");

        selectors = [...selectors, ...selects];

        selectors.map(function(selector) {
            selectorNames.push(selector.name);
        });

        for (var pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }

        let existingNames = formDataArray.map(entry => entry[0]);
        selectorNames = [...new Set(selectorNames)];

        selectorNames.forEach(function (name) {
            let newEntry = [name, ""];
            if(!existingNames.includes(name)) {
                formDataArray.push(newEntry);
            } else {
                formDataArray.filter(item => item !== newEntry);
            }
        });
    });

    return formDataArray;
}

function transformDataSetOptions(options = {}) {
    let transformedOptions = {...options};

    if (transformedOptions.fetchHeaders) {
        try {
            transformedOptions.fetchHeaders = JSON.parse(transformedOptions.fetchHeaders)
        } catch (e) {
            transformedOptions.fetchHeaders = {};
        }
    }

    if (transformedOptions.allowScriptTags) {
        transformedOptions.allowScriptTags = !!(transformedOptions.allowScriptTags === "true" || transformedOptions.allowScriptTags === true)
    }

    return transformedOptions;
}

function getActionByForm(form) {
    return form.dataset.action || form.getAttribute('action') || location.pathname
}

function checkForLoaderRemoveBtn(myModalEl, loadingElements) {
    const loaderRemoveBtn = findIn('.js-remove-pds-loader', myModalEl._element);

    if (!loaderRemoveBtn) return;

    loaderRemoveBtn.addEventListener('click', function () {
        loadingElements.map(element => setAttribute('hidden', 'hidden', element));
    });

}
